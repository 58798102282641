import axios from "axios";
import config from "../../config";

export class Metadata {
    public static async getTopics(idToken: string, setTopics: CallableFunction, setErrorText: CallableFunction){

        await axios.get(config['API_URI'] + '/metadata/topics',
        {
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        })
        .then(function(response){
            setTopics(response.data);
        })
        .catch(function(error){

            if(error.response && error.response.data.ErrorMessage !== undefined){
                setErrorText(error.response.data.ErrorMessage);
            }
            else{
                setErrorText("An unknown error has occurred. Please try again later");
            }
        })
    }

    public static async getServices(idToken: string, setServices: CallableFunction, setErrorText: CallableFunction){

        await axios.get(config['API_URI'] + '/metadata/services',
        {
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        })
        .then(function(response){
            setServices(response.data);
        })
        .catch(function(error){

            if(error.response && error.response.data.ErrorMessage !== undefined){
                setErrorText(error.response.data.ErrorMessage);
            }
            else{
                setErrorText("An unknown error has occurred. Please try again later");
            }
        })
    }
}