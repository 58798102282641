import { Alert, Box, SpaceBetween, StatusIndicator, TextContent, Textarea } from "@cloudscape-design/components";
import { Demo } from "../../../../classes/demo";
import React, { useContext } from "react";
import { IDTokenContext } from "../../../../context/idTokenContext";
import { Credentials } from "../../../../classes/credentials";

interface CredentialsModalProps{
    visible: boolean;
    demo: Demo;
}

export default function CredentialsModal({visible, demo}: CredentialsModalProps){

    const [loading, setLoading] = React.useState<boolean>(true);
    const [credentials, setCredentials] = React.useState<Credentials | null>(null);
    const [errorText, setErrorText] = React.useState("");

    const idToken = useContext(IDTokenContext);

    async function GetCredentials(setCredentials: CallableFunction, setErrorText: CallableFunction, setLoading: CallableFunction) {
        setErrorText("");

        if(visible === false){
            setLoading(true);
            return;
        }

        await demo.getCredentials(idToken.toString(), setCredentials, setErrorText);
        setLoading(false);
    }

    React.useEffect(() => {
        GetCredentials(setCredentials, setErrorText, setLoading);
    }, [visible]);
    
    if(visible === false || loading === true){
        return (<StatusIndicator type="loading">Loading</StatusIndicator>);
    }
    else if(errorText.length > 0) {
        return (<Alert statusIconAriaLabel="Error" type="error" header="An error has occurred">{errorText}</Alert>);
    }
    else{

        let credentialsText = "export AWS_DEFAULT_REGION=" + credentials?.default_region + "\n";
        credentialsText = credentialsText + "export AWS_ACCESS_KEY_ID=" + credentials?.aws_access_key_id + "\n";
        credentialsText = credentialsText + "export AWS_SECRET_ACCESS_KEY=" + credentials?.aws_secret_access_key + "\n";
        credentialsText = credentialsText + "export AWS_SESSION_TOKEN=" + credentials?.aws_session_token;

        return (
            <Box>
                <SpaceBetween size="s">
                    <TextContent>
                        <p>The demo repository uses <a href="https://aws.amazon.com/codecommit/">AWS CodeCommit</a> to store demo code. Please review the <a href="https://docs.aws.amazon.com/codecommit/latest/userguide/setting-up.html">CodeCommit - Setting Up</a> documentation before proceeding.</p>
                        <p>Copy the credentials from below into the terminal of your choice. Alternatively configure them into your AWS profile following <a href="https://docs.aws.amazon.com/cli/latest/userguide/cli-authentication-short-term.html">these instructions</a>.</p>
                    </TextContent>
                    <Textarea disabled value={credentialsText} rows={4} />
                    <TextContent>
                        <p>Clone the repository using the command below.</p>
                    </TextContent>
                    <Textarea disabled value={"git clone codecommit://" + credentials?.repository} rows={1} />
                    <TextContent>
                        <p><strong>Please Note: </strong> Write permissions are only supported for the author of the demo.</p>
                    </TextContent>
                </SpaceBetween>
            </Box>
        );
            
    }
}