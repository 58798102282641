import { Box, Container, ExpandableSection, Grid, Header, StatusIndicator } from "@cloudscape-design/components";
import LabelValueContent from "../../../../components/label_value_content";
import CopyToClipboard from "../../../../components/copy_to_clipboard";
import { Demo, DemoStatus } from "../../../../classes/demo";

interface DemoDetailsProps{
    demo: Demo;
}

export default function DemoDetails({demo}: DemoDetailsProps) {
    return (
        <Container
        header={
        <Header variant="h2">
            Demo Details
        </Header>
        }
    >
        <Grid
            gridDefinition={[
            { colspan: { xs: 3, xxs: 12 } },
            { colspan: { xs: 3, xxs: 12 } },
            { colspan: { xs: 3, xxs: 12 } },
            { colspan: { xs: 3, xxs: 12 } },
            { colspan: { xs: 3, xxs: 12 } },
            { colspan: { xs: 3, xxs: 12 } },
            { colspan: { xs: 3, xxs: 12 } },
            /*{ colspan: { xs: 3, xxs: 12 } },*/
            ]}
        >
        
        <LabelValueContent label={"DemoID"} value = {<CopyToClipboard displayValue={demo.demo_id} copyValue={demo.demo_id} />} />
            <LabelValueContent label={"Demo Name"} value={demo.name} />
            <LabelValueContent label={"Topic"} value={demo.topic} />
            <LabelValueContent label={"Services"} value={demo.getServicesFormatted()} />
            <LabelValueContent label={"Status"} value={<DemoStatusIndicator demo={demo} />} />
            <LabelValueContent label={"Author(s)"} value={demo.getAuthorsFormatted()} />
            <LabelValueContent label={"Last Published"} value={demo.last_published} />
            {/* <LabelValueContent label={"Rating"} value={demo.rating} /> */}
        </Grid>
        <Box margin={{top: "m"}}>
            <ExpandableSection headingTagOverride="h5" headerText="Description">
                {demo.description}
            </ExpandableSection>
        </Box>
    </Container>
    )
}

function DemoStatusIndicator({demo}: DemoDetailsProps) {
    if(demo.status === DemoStatus.Draft) {
        return <StatusIndicator type="in-progress">Draft</StatusIndicator>
    }
    else {
        return <StatusIndicator type="success">Published</StatusIndicator>
    }
}