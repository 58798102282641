var config = {
    "USER_POOL_ID": "eu-west-1_CYBwdhrv8",
    "CLIENT_ID": "5ae77mrl91gh71rp7utrsj7bda",
    "COGNITO_DOMAIN": "demo-repo-8a9f3e0b86b2a74.auth.eu-west-1.amazoncognito.com",
    "REDIRECT_URI": "http://localhost:3000/",
    "API_URI": "https://dev.demos.aws.dev/api"
}

if(window.location.hostname === 'dev.demos.aws.dev')
{
    config = {
        "USER_POOL_ID": "eu-west-1_CYBwdhrv8",
        "CLIENT_ID": "5ae77mrl91gh71rp7utrsj7bda",
        "COGNITO_DOMAIN": "demo-repo-8a9f3e0b86b2a74.auth.eu-west-1.amazoncognito.com",
        "REDIRECT_URI": "https://dev.demos.aws.dev/",
        "API_URI": "https://dev.demos.aws.dev/api"
    }
}
else if(window.location.hostname === 'preview.demos.aws.dev')
{
    config = {
        "USER_POOL_ID": "eu-west-1_tLhwLBvoU",
        "CLIENT_ID": "1mo6sndu5mg3o709os2crvb8n3",
        "COGNITO_DOMAIN": "demo-repo-e29330fe71cf5ef.auth.eu-west-1.amazoncognito.com",
        "REDIRECT_URI": "https://preview.demos.aws.dev/",
        "API_URI": "https://preview.demos.aws.dev/api"
    }
}

export default config;