import { Alert, BreadcrumbGroup, Button, Container, Form, FormField, Header, Input, Multiselect, Select, SpaceBetween, StatusIndicator, Textarea } from "@cloudscape-design/components";
import StandardLayout from "../../components/standard_layout";
import React, { useContext } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Demo } from "../../classes/demo";
import { IDTokenContext } from "../../context/idTokenContext";
import { Metadata } from "../../classes/metadata";

interface CreateDemoPageContentProps{
    topics_list: string[],
    services_list: string[]
}

export default function CreateDemoPage(){

    const [loading, setLoading] = React.useState<boolean>(true);
    const [errorText, setErrorText] = React.useState("");
    const [topicsList, setTopicsList] = React.useState<string[]>([]);
    const [servicesList, setServicesList] = React.useState<string[]>([]);

    const idToken = useContext(IDTokenContext);

    async function PreparePage(setTopicsList: CallableFunction, setServicesList: CallableFunction, setLoading: CallableFunction) {
        await Metadata.getTopics(idToken.toString(), setTopicsList, setErrorText)
        await Metadata.getServices(idToken.toString(), setServicesList, setErrorText)
        setLoading(false);
    }

    React.useEffect(() => {
        PreparePage(setTopicsList, setServicesList, setLoading);
    }, [loading]);

    if(loading === true){
        return (
            <StandardLayout
                content={<StatusIndicator type="loading">Loading</StatusIndicator>}
            />
        );
    }
    else if(errorText.length > 0) {
        return (
            <StandardLayout
                content={
                    <Alert
                        statusIconAriaLabel="Error"
                        type="error"
                        header="An error has occurred"
                    >
                        {errorText}
                    </Alert>
                }
            />
        );
    }
    else {
        return (
            <StandardLayout
                content={<CreateDemoPageContent topics_list={topicsList as string[]} services_list={servicesList as string[]} /> }
                layout_props={{
                    breadcrumbs: (<CreateDemoBreadcrumbs />)
                }}
            />
        );
    }
}

function CreateDemoPageContent({topics_list, services_list}: CreateDemoPageContentProps){

    const [title, setTitle] = React.useState("");
    const [description, setDescription] = React.useState("");
    const [topic, setTopic] = React.useState<OptionDefinition | null>(null);
    const [services, setServices] = React.useState<Readonly<OptionDefinition[]>>([]);
    const [formProcessing, setFormProcessing] = React.useState<boolean | undefined>(undefined);
    const [titleErrorText, setTitleErrorText] = React.useState("");
    const [descriptionErrorText, setDescriptionErrorText] = React.useState("");
    const [topicErrorText, setTopicErrorText] = React.useState("");
    const [servicesErrorText, setServicesErrorText] = React.useState("");
    const [responseErrorText, setResponseErrorText] = React.useState("");

    const idToken = useContext(IDTokenContext);

    let topicOptions: any[] = [];

    for(let topic in topics_list){
        topicOptions.push({
            label: topics_list[topic],
            value: topics_list[topic]
        });
    }

    let servicesOptions: any[] = [];

    for(let service in services_list){
        servicesOptions.push({
            label: services_list[service],
            value: services_list[service]
        });
    }

    return (
        <form onSubmit={
            e => {
                e.preventDefault();
                setFormProcessing(true);

                let isValid = false;
                if(
                    isTitleValid(title, setTitleErrorText)
                    &&
                    isDescriptionValid(description, setDescriptionErrorText)
                    &&
                    isTopicValid(topic, setTopicErrorText)
                    &&
                    isServicesValid(services, setServicesErrorText)
                
                ){
                    isValid = true;
                }

                if(isValid){
                    SubmitForm(idToken.toString(), title, description,topic?.value as string, services, setResponseErrorText);
                    setFormProcessing(undefined);
                }
                else{
                    setFormProcessing(undefined);
                }
            }
        }>
            <Form
               header={
                    <Header
                        variant="h1"
                    >Create Demo</Header>
                }
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="primary" disabled={formProcessing}>Submit</Button>
                    </SpaceBetween>
                }
            >
                <Container
                    header={
                        <Header variant="h2">Demo Details</Header>
                    }
                >
                    <SpaceBetween size="m">
                        {responseErrorText !== "" && <Alert
                            statusIconAriaLabel="Error"
                            type="error"
                            header={
                                <React.Fragment>An error occurred</React.Fragment>
                            }
                        >
                            {responseErrorText}
                        </Alert>}
                        <FormField
                            label="Title"
                            errorText={titleErrorText}
                        >
                            <Input type="text" placeholder="Demo Name" value={title} onChange={({ detail }) => {isTitleValid(detail.value, setTitleErrorText); setTitle(detail.value)}} />
                        </FormField>
                        <FormField
                            label="Description"
                            errorText={descriptionErrorText}
                        >
                            <Textarea value={description} onChange={({ detail }) => { isDescriptionValid(detail.value, setDescriptionErrorText); setDescription(detail.value)}} />
                        </FormField>
                        <FormField
                            label="Topic"
                            errorText={topicErrorText}
                        >
                            <Select
                                options={topicOptions}
                                placeholder="Choose a topic"
                                selectedOption={topic}
                                onChange={({ detail }) => {
                                    isTopicValid(detail.selectedOption, setTopicErrorText);
                                    setTopic(detail.selectedOption);
                                }}
                                filteringType="auto"
                            />
                        </FormField>
                        <FormField
                            label="Service(s)"
                            errorText={servicesErrorText}
                        >
                            <Multiselect
                                selectedOptions={services}
                                onChange={({ detail }) => {
                                    isServicesValid(detail.selectedOptions, setServicesErrorText);
                                    setServices(detail.selectedOptions);
                                }}
                                options={servicesOptions}
                                placeholder="Choose options"
                                empty="No options"
                                filteringType="auto"
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    );
}

function CreateDemoBreadcrumbs() {
    return (<BreadcrumbGroup items={[
        { text: "Demo Repository", href: "/" },
        { text: "My Demos", href: "/author/search" },
        { text: "Create Demo", href: "/demo/create"}
    ]} />);
}

async function SubmitForm(id_token: string, title: string, description: string, topic: string, services: Readonly<OptionDefinition[]>, setResponseErrorText: CallableFunction) {

    let formatted_services = [];

    for(let service of services) {
        formatted_services.push(service.value);
    }

    await Demo.createDemo(id_token, title, description, topic, formatted_services as string[], setResponseErrorText, redirectToViewDetails);
}

function redirectToViewDetails(demoId: string) {
    window.location.href = "/demo/" + demoId;
}

function isTitleValid(title: string, callback: CallableFunction) {
    let isValid = title.length > 0;

    if(!isValid){
        callback("Please enter a valid title");
    }
    else{
        callback("");
    }

    return isValid;
}

function isDescriptionValid(description: string, callback: CallableFunction) {
    let isValid = description.length > 0;

    if(!isValid){
        callback("Please enter a valid description");
    }
    else{
        callback("");
    }

    return isValid;
}

function isTopicValid(topic: OptionDefinition | null, callback: CallableFunction) {

    let isValid = topic !== null;

    if(!isValid){
        callback("Please select a topic");
    }
    else{
        callback("");
    }

    return isValid;
}

function isServicesValid(services: readonly OptionDefinition[], callback: CallableFunction) {

    let isValid = services.length > 0;

    if(!isValid){
        callback("Please select at least one service");
    }
    else{
        callback("");
    }

    return isValid;
}