import React, { useEffect, useState } from 'react';
import "@cloudscape-design/global-styles/index.css"
import { Routes, Route } from "react-router-dom";
import HomePage from './pages/home_page';
import { Amplify } from 'aws-amplify';
import { getCurrentUser, signInWithRedirect, fetchAuthSession, JWT } from 'aws-amplify/auth';
import config from './config';
import './App.css';
import { IDTokenContext } from './context/idTokenContext';
import AuthorSearchPage from './pages/author_search';
import DetailedDemoViewPage from './pages/detailed_demo_view';
import ConsumerSearchPage from './pages/consumer_search';
import PendingReviewsPage from './pages/pending_reviews';
import CreateDemoPage from './pages/create_demo';
import EditDemoPage from './pages/edit_demo';

Amplify.configure({
  Auth: {
      Cognito: {
        userPoolId: config.USER_POOL_ID,
        userPoolClientId: config.CLIENT_ID,
        loginWith: {
          oauth: {
            domain: config.COGNITO_DOMAIN,
            scopes: [
              'openid',
            ],
            redirectSignIn: [config.REDIRECT_URI],
            redirectSignOut: [config.REDIRECT_URI],
            responseType: 'code'
          }
        }
      }
      
  }
})

function App() {
  const [isAuthenticated, setIsAuthenticated,] = useState(false);
  const [idToken, setIdToken,] = useState<JWT | undefined>();

  useEffect(() => {

    const authenticate = async () => {
      try {
        await getCurrentUser();

        if(!idToken)
        {
          const idToken = (await fetchAuthSession()).tokens?.idToken;
          setIdToken(idToken);
        }
        
        setIsAuthenticated(true);

      } catch {
        setIsAuthenticated(false);
        signInWithRedirect({});
      }
    }

    authenticate();

  }, [isAuthenticated, idToken]);

  if (isAuthenticated && idToken) {

    return (
      <>
        <IDTokenContext.Provider value={idToken}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/author/search" element={<AuthorSearchPage />} />
            <Route path="/search" element={<ConsumerSearchPage />} />
            <Route path="/reviews" element={<PendingReviewsPage />} />
            <Route path="/demo/create" element={<CreateDemoPage />} />
            <Route path="/demo/:demoId" element={<DetailedDemoViewPage />} />
            <Route path="/demo/:demoId/edit" element={<EditDemoPage />} />
          </Routes>
        </IDTokenContext.Provider>
      </>
    );
  }
  else
  {
    return (
      <p>Loading...</p>
    )
  }
}

export default App;
