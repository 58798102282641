import Box from "@cloudscape-design/components/box";
import Container from "@cloudscape-design/components/container";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import step_1 from "../../../../static/images/step1.png"
import step_2 from "../../../../static/images/step2.png"
import step_3 from "../../../../static/images/step3.png"

export default function HowDoesItWork() {
    return (
        <>
            <Box variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                    How does it work?
            </Box>
            <Container>
                <ColumnLayout columns={3} borders="vertical">
                <Box textAlign="center">
                    <img style={{ width: '40%' }} src={step_1} />
                    <h3>Step 1</h3>
                    <p>Click on the "Get Started" link and fill out the form to define the parameters for the demo you want to create.</p>
                </Box>
                <Box textAlign="center">
                    <img style={{ width: '40%' }} src={step_2} />
                    <h3>Step 2</h3>
                    <p>Clone the generated Git repository to your local development environment. Build out your demo code within this repository.</p>
                </Box>
                <Box textAlign="center">
                    <img style={{ width: '40%' }} src={step_3} />
                    <h3>Step 3</h3>
                    <p>Push your finalized code back up to the hosted Git repository. Upload any related media assets.</p>
                </Box>
                </ColumnLayout>
            </Container>
        </>
    )
}