import { Header, HelpPanel } from "@cloudscape-design/components";
import LayoutHeader from "../layout_header"
import LeftSideNavigation from "../left_side_navigation";
import AppLayout from '@cloudscape-design/components/app-layout';

interface ContentProps {
  content: React.ReactNode;
  layout_props?: any;
}

export default function StandardLayout({content, layout_props}: ContentProps) {

    return (
        <div>
            <LayoutHeader />
            <AppLayout
                content={content}
                navigation={<LeftSideNavigation />}
                {...layout_props}
                tools={<HelpPanel>
                    <Header>Demo Repository</Header>
                    <p>Welcome to the Demo Repository Preview.</p>
                    <p>Please note that several features are still being implemented. We ask that during the preview you raise issues on functionality that is supported rather than placeholder values.</p>
                </HelpPanel>}
            />
        </div>
    )
}