import SideNavigation, { SideNavigationProps } from '@cloudscape-design/components/side-navigation';
import Badge from "@cloudscape-design/components/badge";

const items: SideNavigationProps['items'] = [
  {
      type: "section",
      text: "Demos",
      items: [
          { type: 'link', text: 'Find Demos', href: '/search' },
          { type: 'link', text: 'My Demos', href: '/author/search' },
       ]
  },
  {
      type: "section",
      text: "Reviews",
      items: [
          { type: 'link', text: 'Outstanding Reviews', href: '/reviews', info: <Badge color="green">0</Badge> },
       ]
  },
  { type: "divider" },
  { type: 'link', text: 'Documentation', href: '#', external: true },
  { type: 'link', text: 'Wiki', href: 'https://w.amazon.com/bin/view/UKIRISV/Team-Initiatives/ISV-Demo-Repo/', external: true },
  { type: 'link', text: 'FAQs', href: 'https://w.amazon.com/bin/view/UKIRISV/Team-Initiatives/ISV-Demo-Repo/#HFrequentlyAskedQuestions28FAQs29', external: true },
];

export default function LeftSideNavigation() {
  return (
      <SideNavigation
        activeHref={window.location.pathname}
        header={{ href: '/', text: 'Demo Repository' }}
        items={items}
      />
  );
}