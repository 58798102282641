import { BreadcrumbGroup } from '@cloudscape-design/components';
import StandardLayout from '../../components/standard_layout';
import DemoListTable from '../../components/demo_list_table';
import React from 'react';

interface ConsumerSearchPageContentProps{
    setErrorText: CallableFunction
}

export default function ConsumerSearchPage() {

    const [errorText, setErrorText] = React.useState("");

    return (
      <StandardLayout 
        content={<ConsumerSearchPageContent setErrorText={setErrorText} />}
        layout_props={
            {
                breadcrumbs: (<ConsumerSearchPageBreadcrumbs />)
            }
        }
    />
    );
  }

function ConsumerSearchPageContent({setErrorText}: ConsumerSearchPageContentProps) {
    return (
        <DemoListTable headerTitle="Demos" setErrorText={setErrorText} />
    );
}

function ConsumerSearchPageBreadcrumbs() {
    return (<BreadcrumbGroup items={[
        { text: "Demo Repository", href: "/" },
        { text: "Demos", href: "/search" }
    ]} />);
}