interface CredentialsProps{
    default_region: string;
    aws_access_key_id: string;
    aws_secret_access_key: string;
    aws_session_token: string;
    repository: string;
}

export class Credentials{
    /**
     * The default region for the credentials
     */
    public readonly default_region: string;

    /**
     * The temporary access key ID to access the repository
     */
    public readonly aws_access_key_id: string;

    /**
     * The temporary secret access key to access the repository
     */
    public readonly aws_secret_access_key: string;

    /**
     * The temporary session token to access the repository
     */
    public readonly aws_session_token: string;

    /**
     * The repository name
     */
    public readonly repository: string;

    public constructor({default_region, aws_access_key_id, aws_secret_access_key, aws_session_token, repository}: CredentialsProps){
        this.default_region = default_region;
        this.aws_access_key_id = aws_access_key_id;
        this.aws_secret_access_key = aws_secret_access_key;
        this.aws_session_token = aws_session_token;
        this.repository = repository;
    }
}