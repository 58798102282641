import { BreadcrumbGroup } from '@cloudscape-design/components';
import StandardLayout from '../../components/standard_layout';
import DemoListTable from '../../components/demo_list_table';
import React from 'react';

interface AuthorSearchPageContent{
    setErrorText: CallableFunction
}

export default function AuthorSearchPage() {
    const [errorText, setErrorText] = React.useState("");

    return (
      <StandardLayout 
        content={<AuthorSearchPageContent setErrorText={setErrorText} />}
        layout_props={
            {
                breadcrumbs: (<AuthorSearchPageBreadcrumbs />)
            }
        }
    />
    );
  }

function AuthorSearchPageContent({setErrorText}: AuthorSearchPageContent) {
    return (
        <DemoListTable headerTitle="My Demos" editable={true} setErrorText={setErrorText} />
    );
}

function AuthorSearchPageBreadcrumbs() {
    return (<BreadcrumbGroup items={[
        { text: "Demo Repository", href: "/" },
        { text: "My Demos", href: "/author/search" }
    ]} />);
}