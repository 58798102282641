import { BreadcrumbGroup } from '@cloudscape-design/components';
import StandardLayout from '../../components/standard_layout';
import DemoListTable from '../../components/demo_list_table';
import React from 'react';

interface PendingReviewsPageContentProps{
    setErrorText: CallableFunction
}

export default function PendingReviewsPage() {
    const [errorText, setErrorText] = React.useState("");

    return (
      <StandardLayout 
        content={<PendingReviewsPageContent setErrorText={setErrorText} />}
        layout_props={
            {
                breadcrumbs: (<PendingReviewsPageBreadcrumbs />)
            }
        }
    />
    );
  }

function PendingReviewsPageContent({setErrorText}:PendingReviewsPageContentProps) {
    return (
        <DemoListTable headerTitle="Pending Reviews" hideCreate={true} filteringPlaceholderText={"Filter Reviews"} displayBuildVersion={true} setErrorText={setErrorText} />
    );
}

function PendingReviewsPageBreadcrumbs() {
    return (<BreadcrumbGroup items={[
        { text: "Demo Repository", href: "/" },
        { text: "Demos", href: "/search" }
    ]} />);
}