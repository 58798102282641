import { AttributeEditor, Box, Input, Select, Textarea } from "@cloudscape-design/components";
import LabelValueContent from "../../../../components/label_value_content";
import React from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export default function ManageReviewersModal(){
    const [reviewers, setReviewers] = React.useState<any[]>([]);

    return(
        <Box>
            <AttributeEditor
                onAddButtonClick={() => setReviewers([...reviewers, {}])}
                onRemoveButtonClick={({
                    detail: { itemIndex }
                    }) => {
                    const tmpItems = [...reviewers];
                    tmpItems.splice(itemIndex, 1);
                    setReviewers(tmpItems);
                }}
                items={reviewers}
                addButtonText="Add New Reviewer"
                definition={[
                    {
                        label: "Reviewer",
                        control: item => (
                            
                            <Input
                                value={item.key}
                                placeholder="Enter key"
                            />
                        )
                    }
                ]}
            />
        </Box>
    )
}