import axios from "axios";
import { Credentials } from "../credentials";
import config from "../../config";

interface DemoProps{
    demo_id: string;
    demo_name: string;
    topic: string;
    services: string[];
    status: DemoStatus;
    authors: string[];
    last_published: string;
    rating: number;
    description: string;
}

export enum DemoStatus {
    Published = 'published',
    Draft = 'draft'
}

/**
 * Class that contains the constructors and support methods for a Demo
 */
export class Demo {

    /**
     * The ID of the demo
     */
    public readonly demo_id: string;

    /**
     * The name of the demo
     */
    public name: string;

    /**
     * The topic for the demo
     */
    public topic: string;

    /**
     * The services used in the demo
     */
    public services: string[];

    /**
     * The status of the demo
     */
    public readonly status: DemoStatus;

    /**
     * The author(s) of the demo
     */
    public readonly authors: any[];

    /**
     * The last publication date
     */
    public readonly last_published: string;

    /**
     * The demos rating
     */
    public readonly rating: number

    /**
     * The description of the demo
     */
    public description: string;

    /**
     * Constructor for Demo class
     */
    public constructor({demo_id, demo_name, topic, services, status, authors, last_published, rating, description}: DemoProps) {
        this.demo_id = demo_id;
        this.name = demo_name;
        this.topic = topic;
        this.services = services;
        this.status = status;
        this.authors = authors;
        this.last_published = last_published;
        this.rating = rating;
        this.description = description;
    }

    /**
     * List all services with formatting
     * @returns string
     */
    public getServicesFormatted() {
        return this.services.join(", ");
    }

    /**
     * List all authors with formatting
     * @returns string
     */
    public getAuthorsFormatted() {
        let authorNames = [];

        for(let author in this.authors) {
            authorNames.push(this.authors[author].name);
        }

        return authorNames.join(", ")
    }

    public setName(name: string) {
        this.name = name;
    }

    public setDescription(description: string) {
        this.description = description;
    }

    public setTopic(topic: string) {
        this.topic = topic;
    }

    public setServices(services: string[]) {
        this.services = services;
    }

    public static async createDemo(idToken: string, name: string, description: string, topic: string, services: string[], setErrorText: CallableFunction, successAction: CallableFunction){

        await axios.post(config['API_URI'] + '/demo', {
            Title: name,
            Description: description,
            Topic: topic,
            Services: services,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idToken
            }
        })
        .then(function(response){
            if(response.data.DemoId !== undefined){
                successAction(response.data.DemoId);
                return true;
            }

            setErrorText("Something unexpected happened. Please try again later");
            return false;
        })
        .catch(function(error){
            if(error.response && error.response.data.ErrorMessage !== undefined){
                setErrorText(error.response.data.ErrorMessage);
            }
            else{
                setErrorText("An unknown error has occurred. Please try again later");
            }

            return false;
        });

    }

    public static async getDemoById(idToken: string, demoId: string, setDemo: CallableFunction, setErrorText: CallableFunction){

        await axios.get(config['API_URI'] + '/demo/'+demoId,
        {
            headers: {
                'Authorization': 'Bearer ' + idToken
            }
        })
        .then(function(response){
            let demoStatus = (response.data.Status === "PUBLISHED" ? DemoStatus.Published : DemoStatus.Draft);

            let demo = new Demo({
                demo_id: response.data.DemoId,
                demo_name: response.data.Title,
                topic: response.data.Topic,
                services: response.data.Services,
                status: demoStatus,
                authors: response.data.Authors,
                last_published: response.data.LastPublished,
                rating: response.data.Rating,
                description: response.data.Description,
            });

            setDemo(demo);
        })
        .catch(function(error){

            if(error.response && error.response.data.ErrorMessage !== undefined){
                setErrorText(error.response.data.ErrorMessage);
            }
            else{
                setErrorText("An unknown error has occurred. Please try again later");
            }
        })

        return false;
    }

    public async save(idToken: string, setErrorText: CallableFunction, successAction: CallableFunction){
        await axios.put(config['API_URI'] + '/demo/' + this.demo_id + '/metadata', {
            Title: this.name,
            Description: this.description,
            Topic: this.topic,
            Services: this.services,
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idToken
            }
        })
        .then(async (response) => {
            if(response.status === 204){
                successAction(this.demo_id);
                return true;
            }

            setErrorText("Something unexpected happened. Please try again later");
            return false;
        })
        .catch(function(error){
            if(error.response && error.response.data.ErrorMessage !== undefined){
                setErrorText(error.response.data.ErrorMessage);
            }
            else{
                setErrorText("An unknown error has occurred. Please try again later");
            }

            return false;
        });
    } 

    public async getCredentials(idToken: string, setCredentials: CallableFunction, setErrorText: CallableFunction){
        await axios.get(config['API_URI'] + '/demo/' + this.demo_id + '/credentials',
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + idToken
            }
        })
        .then(async (response) => {
            let credentials = new Credentials({
                aws_access_key_id: response.data.AccessKeyId,
                aws_secret_access_key: response.data.SecretAccessKey,
                aws_session_token: response.data.SessionToken,
                repository: response.data.RepositoryName,
                default_region: response.data.DefaultRegion
            });

            setCredentials(credentials);
        })
        .catch(function(error){
            if(error.response && error.response.data.ErrorMessage !== undefined){
                setErrorText(error.response.data.ErrorMessage);
            }
            else{
                setErrorText("An unknown error has occurred. Please try again later");
            }

            return false;
        });
    }
}