import { Alert, Box, BreadcrumbGroup, Button, ContentLayout, Header, Modal, SpaceBetween, StatusIndicator } from "@cloudscape-design/components";
import StandardLayout from "../../components/standard_layout";
import {useParams} from "react-router-dom";
import DemoDetails from "./components/demo_details";
import { Demo, DemoStatus } from "../../classes/demo";
import React, { useContext } from "react";
import AdditionalDetails from "./components/additional_details";
import CredentialsModal from "./components/credentials_modal";
import FlagDemoModal from "./components/flag_demo_modal";
import SubmitFeedbackModal from "./components/submit_feedback_modal";
import ManageReviewersModal from "./components/manage_reviewers_modal";
import { IDTokenContext } from "../../context/idTokenContext";

interface DetailedDemoViewProps {
    demo: Demo;
    display_view: DemoDisplayView;
}

interface DemoBreadcrumbsProps {
    display_view: DemoDisplayView;
    demo: Demo;
}

export enum DemoDisplayView {
    Author = 'author',
    Consumer = 'consumer',
    Reviewer = 'reviewer'
}

export default function DetailedDemoViewPage() {
    const idToken = useContext(IDTokenContext);
    const {demoId} = useParams();

    const [demo, setDemo] = React.useState<Demo | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [errorText, setErrorText] = React.useState("");

    async function GetDemo(demoId: string, setLoading: any) {
        await Demo.getDemoById(idToken.toString(), demoId, setDemo, setErrorText)
        setLoading(false);
      }
  
      React.useEffect(() => {
        GetDemo(demoId as string, setLoading);
      }, [demoId]);

    if(loading === true){
        return (
            <StandardLayout
                content={<StatusIndicator type="loading">Loading</StatusIndicator>}
            />
        );
    }
    else if(errorText.length > 0) {
        return (
            <StandardLayout
                content={
                    <Alert
                        statusIconAriaLabel="Error"
                        type="error"
                        header="An error has occurred"
                    >
                        {errorText}
                    </Alert>
                }
            />
        );
    }
    else {

        let display_view=DemoDisplayView.Consumer;

        if(isCurrentUserDemoOwner(demo as Demo, idToken.payload.identities as any[])){
            display_view=DemoDisplayView.Author;
        }
        else if(demo!.status === DemoStatus.Draft){
            display_view=DemoDisplayView.Reviewer;
        }

        return (
            <StandardLayout
                content={
                    <DetailedDemoViewContent demo={demo as Demo} display_view={display_view} />
                }
                layout_props={{
                    breadcrumbs: (<DemoBreadcrumbs demo={demo as Demo} display_view={display_view} />)
                }}
            />
        );
    }
}

function isCurrentUserDemoOwner(demo: Demo, identities: any[]){
    let identity = identities[0];

    for(let author of demo.authors){
        if(author.alias === identity.userId){
            return true;
        }
    }

    return false;
}

function DetailedDemoViewContent({demo, display_view}: DetailedDemoViewProps) {

    const [credentialsModalVisible, setCredentialsModalVisible] = React.useState(false);
    const [flagDemoModalVisible, setFlagDemoModalVisible] = React.useState(false);
    const [submitFeedbackModalVisible, setSubmitFeedbackModalVisible] = React.useState(false);
    const [manageReviewersModalVisible, setManageReviewersModalVisible] = React.useState(false);

    return (
         <ContentLayout
            header={
                <Header
                    variant="h1"
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            {/* display_view === DemoDisplayView.Reviewer && <Button variant="normal" onClick={() => {setSubmitFeedbackModalVisible(true)}}>
                                Submit Feedback
                            </Button>}
                            {display_view === DemoDisplayView.Consumer && <Button variant="normal" onClick={() => {setFlagDemoModalVisible(true)}}>
                                Flag Demo
                            </Button>}
                            {display_view === DemoDisplayView.Author && <Button variant="normal" onClick={() => {setManageReviewersModalVisible(true)}}>
                                Manage Reviewers
                            </Button>*/}
                            {display_view === DemoDisplayView.Author && <Button variant="normal" href={"/demo/" + demo.demo_id + "/edit"}>
                                Edit
                            </Button>}
                            <Button variant="primary" onClick={() => {setCredentialsModalVisible(true)}}>
                                Get Credentials
                            </Button>
                        </SpaceBetween>
                    }
                >
                    {demo.demo_id}
                </Header>
            }
        >
            <SpaceBetween size="m">
                <DemoDetails demo={demo} />
                {/* <AdditionalDetails demo={demo} display_view={display_view} /> */}
            </SpaceBetween>
            <Modal onDismiss={() => setCredentialsModalVisible(false)} visible={credentialsModalVisible} header="Clone Demo">
                <CredentialsModal visible={credentialsModalVisible} demo={demo} />
            </Modal>
            <Modal
                onDismiss={() => setFlagDemoModalVisible(false)} visible={flagDemoModalVisible} header="Flag Demo"
                footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => {setFlagDemoModalVisible(false)}}>Cancel</Button>
                        <Button variant="primary" onClick={() => {setFlagDemoModalVisible(false)}}>Submit</Button>
                      </SpaceBetween>
                    </Box>
                  }
            >
                <FlagDemoModal />
            </Modal>
            <Modal
                onDismiss={() => setSubmitFeedbackModalVisible(false)} visible={submitFeedbackModalVisible} header="Feedback"
                footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => {setSubmitFeedbackModalVisible(false)}}>Cancel</Button>
                        <Button variant="primary" onClick={() => {setSubmitFeedbackModalVisible(false)}}>Submit</Button>
                      </SpaceBetween>
                    </Box>
                  }
            >
                <SubmitFeedbackModal />
            </Modal>
            <Modal
                onDismiss={() => setManageReviewersModalVisible(false)} visible={manageReviewersModalVisible} header="Manage Reviewers"
                footer={
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => {setManageReviewersModalVisible(false)}}>Cancel</Button>
                        <Button variant="primary" onClick={() => {setManageReviewersModalVisible(false)}}>Submit</Button>
                      </SpaceBetween>
                    </Box>
                  }
            >
                <ManageReviewersModal />
            </Modal>
        </ContentLayout>
    );
}

function DemoBreadcrumbs({display_view, demo}: DemoBreadcrumbsProps) {
    if(display_view === DemoDisplayView.Author) {
        return (<BreadcrumbGroup items={[
            { text: "Demo Repository", href: "/" },
            { text: "My Demos", href: "/author/search" },
            { text: demo.name, href: "/demo/"}
        ]} />);
    }
    else if(display_view === DemoDisplayView.Reviewer) {
        return (<BreadcrumbGroup items={[
            { text: "Demo Repository", href: "/" },
            { text: "Pending Reviews", href: "/reviews" },
            { text: demo.name, href: "/demo/"}
        ]} />);
    }

    return (<BreadcrumbGroup items={[
        { text: "Demo Repository", href: "/" },
        { text: "Demos", href: "/search" },
        { text: demo.name, href: "/demo/"}
    ]} />);
}