import { Box } from '@cloudscape-design/components';
import StandardLayout from '../../components/standard_layout';
import HomePageHeader from './components/home_page_header';
import HowDoesItWork from './components/how_does_it_work';
import FeaturesSection from './components/features_section';
import Grid from "@cloudscape-design/components/grid";
import MoreLinksSection from './components/more_links_section';

import './styles/home_page.scss';

export default function HomePage() {

  return (
    <StandardLayout content={<HomePageContent />}  />
  );
}

export function HomePageContent() {
  return (
    <Box margin={{ bottom: 'l' }}>
      <HomePageHeader />
      <Box padding={{ top: 'xxxl', horizontal: 's' }}>
        <Grid
            gridDefinition={[
              { colspan: { default: 12, m: 9, l: 10 } },
              { colspan: { default: 12, m: 3, l: 2 } }
            ]}
          >
            <div>
              <HowDoesItWork />
              <FeaturesSection />
            </div>
            <div>
              <MoreLinksSection />
            </div>
          </Grid>
        </Box>
    </Box>
  );
}