import { Box } from "@cloudscape-design/components";
import React from "react";

interface LabelValueProps {
    label: string;
    value: string | number | React.ReactElement;
}

export default function LabelValueContent({label, value}: LabelValueProps) {
    return (
        <div>
            <Box variant="awsui-key-label">{label}</Box>
            <div>{value}</div>
        </div>
    )
}