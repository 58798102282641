import { Alert, BreadcrumbGroup, Button, Container, Form, FormField, Header, Input, Multiselect, Select, SpaceBetween, StatusIndicator, Textarea } from "@cloudscape-design/components";
import StandardLayout from "../../components/standard_layout";
import React, { useContext } from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";
import { Demo } from "../../classes/demo";
import { useParams } from "react-router-dom";
import { IDTokenContext } from "../../context/idTokenContext";
import { Metadata } from "../../classes/metadata";

interface EditDemoPageContentProps {
    idToken: string,
    demo: Demo,
    topics_list: string[],
    services_list: string[]
}

interface EditDemoBreadcrumbsProps {
    demo: Demo
}

export default function EditDemoPage(){
    const {demoId} = useParams();

    const [demo, setDemo] = React.useState<Demo | undefined>(undefined);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [errorText, setErrorText] = React.useState("");
    const [topicsList, setTopicsList] = React.useState<string[]>([]);
    const [servicesList, setServicesList] = React.useState<string[]>([]);

    const idToken = useContext(IDTokenContext);

    async function GetDemo(demoId: string, setTopicsList: CallableFunction, setServicesList: CallableFunction, setLoading: CallableFunction) {
        await Demo.getDemoById(idToken.toString(), demoId, setDemo, setErrorText)
        await Metadata.getTopics(idToken.toString(), setTopicsList, setErrorText)
        await Metadata.getServices(idToken.toString(), setServicesList, setErrorText)
        setLoading(false);
    }

    React.useEffect(() => {
        GetDemo(demoId as string, setTopicsList, setServicesList, setLoading);
    }, [demoId]);

    if(loading === true){
        return (
            <StandardLayout
                content={<StatusIndicator type="loading">Loading</StatusIndicator>}
            />
        );
    }
    else if(errorText.length > 0) {
        return (
            <StandardLayout
                content={
                    <Alert
                        statusIconAriaLabel="Error"
                        type="error"
                        header="An error has occurred"
                    >
                        {errorText}
                    </Alert>
                }
            />
        );
    }
    else {
        return (
            <StandardLayout
                content={<EditDemoPageContent idToken={idToken.toString()} demo={demo as Demo} topics_list={topicsList} services_list={servicesList}/> }
                layout_props={{
                    breadcrumbs: (<EditDemoBreadcrumbs demo={demo as Demo} />)
                }}
            />
        );
    }
}

function EditDemoPageContent({idToken, demo, topics_list, services_list}: EditDemoPageContentProps){
    let selectedServices = [];

    for (const { i, service } of demo.services.map((service, i) => ({ i, service }))) {
        selectedServices.push({label: service, value: service})
    }

    const [title, setTitle] = React.useState(demo.name);
    const [description, setDescription] = React.useState(demo.description);
    const [topic, setTopic] = React.useState<OptionDefinition | null>({label: demo.topic, value: demo.topic});
    const [services, setServices] = React.useState<Readonly<OptionDefinition[]>>(selectedServices);
    const [formProcessing, setFormProcessing] = React.useState<boolean | undefined>(undefined);
    const [titleErrorText, setTitleErrorText] = React.useState("");
    const [descriptionErrorText, setDescriptionErrorText] = React.useState("");
    const [topicErrorText, setTopicErrorText] = React.useState("");
    const [servicesErrorText, setServicesErrorText] = React.useState("");
    const [responseErrorText, setResponseErrorText] = React.useState("");

    let topicOptions: any[] = [];

    for(let topic in topics_list){
        topicOptions.push({
            label: topics_list[topic],
            value: topics_list[topic]
        });
    }

    let servicesOptions: any[] = [];

    for(let service in services_list){
        servicesOptions.push({
            label: services_list[service],
            value: services_list[service]
        });
    }

    return (
        <form onSubmit={
            e => {
                e.preventDefault();
                setFormProcessing(true);

                let isValid = false;
                if(
                    isTitleValid(title, setTitleErrorText)
                    &&
                    isDescriptionValid(description, setDescriptionErrorText)
                    &&
                    isTopicValid(topic, setTopicErrorText)
                    &&
                    isServicesValid(services, setServicesErrorText)
                
                ){
                    isValid = true;
                }

                if(isValid){
                    SubmitForm(idToken, demo, title, description,topic?.value as string, services, setResponseErrorText);
                    setFormProcessing(undefined);
                }
                else{
                    setFormProcessing(undefined);
                }
            }
        }>
            <Form
               header={
                    <Header
                        variant="h1"
                    >Edit Demo</Header>
                }
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button variant="primary" disabled={formProcessing}>Submit</Button>
                    </SpaceBetween>
                }
            >
                <Container
                    header={
                        <Header variant="h2">Demo Details</Header>
                    }
                >
                    <SpaceBetween size="m">
                    {responseErrorText !== "" && <Alert
                            statusIconAriaLabel="Error"
                            type="error"
                            header={
                                <React.Fragment>An error occurred</React.Fragment>
                            }
                        >
                            {responseErrorText}
                        </Alert>}
                        <FormField
                            label="Title"
                            errorText={titleErrorText}
                        >
                            <Input type="text" placeholder="Demo Name" value={title} onChange={({ detail }) => { isTitleValid(detail.value, setTitleErrorText); setTitle(detail.value) }} />
                        </FormField>
                        <FormField
                            label="Description"
                            errorText={descriptionErrorText}
                        >
                            <Textarea value={description} onChange={({ detail }) => { isDescriptionValid(detail.value, setDescriptionErrorText); setDescription(detail.value) }} />
                        </FormField>
                        <FormField
                            label="Topic"
                            errorText={topicErrorText}
                        >
                            <Select
                                options={topicOptions}
                                placeholder="Choose a topic"
                                selectedOption={topic}
                                onChange={({ detail }) => {
                                    isTopicValid(detail.selectedOption, setTopicErrorText);
                                    setTopic(detail.selectedOption)
                                }}
                                filteringType="auto"
                            />
                        </FormField>
                        <FormField
                            label="Service(s)"
                            errorText={servicesErrorText}
                        >
                            <Multiselect
                                selectedOptions={services}
                                onChange={({ detail }) => {
                                    isServicesValid(detail.selectedOptions, setServicesErrorText);
                                    setServices(detail.selectedOptions);

                                }}
                                options={servicesOptions}
                                placeholder="Choose options"
                                empty="No options"
                                filteringType="auto"
                            />
                        </FormField>
                    </SpaceBetween>
                </Container>
            </Form>
        </form>
    );
}

function EditDemoBreadcrumbs({demo}: EditDemoBreadcrumbsProps) {
    return (<BreadcrumbGroup items={[
        { text: "Demo Repository", href: "/" },
        { text: "My Demos", href: "/author/search" },
        { text: "Edit " + demo.name, href: "/demo/" + demo.demo_id + "/edit"}
    ]} />);
}

function SubmitForm(idToken: string, demo: Demo, title: string, description: string, topic: string, services: Readonly<OptionDefinition[]>, errorCallback: CallableFunction) {
    demo.setName(title);
    demo.setDescription(description);
    demo.setTopic(topic);

    let selectedServices = [];

    for(let service of services) {
        selectedServices.push(service.value);
    }

    demo.setServices(selectedServices as string[]);
    demo.save(idToken, errorCallback, redirectToDemo);
}

function redirectToDemo(demo_id: string) {
    window.location.href = "/demo/" + demo_id;
}

function isTitleValid(title: string, callback: CallableFunction) {
    let isValid = title.length > 0;

    if(!isValid){
        callback("Please enter a valid title");
    }
    else{
        callback("");
    }

    return isValid;
}

function isDescriptionValid(description: string, callback: CallableFunction) {
    let isValid = description.length > 0;

    if(!isValid){
        callback("Please enter a valid description");
    }
    else{
        callback("");
    }

    return isValid;
}

function isTopicValid(topic: OptionDefinition | null, callback: CallableFunction) {

    let isValid = topic !== null;

    if(!isValid){
        callback("Please select a topic");
    }
    else{
        callback("");
    }

    return isValid;
}

function isServicesValid(services: readonly OptionDefinition[], callback: CallableFunction) {

    let isValid = services.length > 0;

    if(!isValid){
        callback("Please select at least one service");
    }
    else{
        callback("");
    }

    return isValid;
}