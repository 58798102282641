import { Box, Container, Grid } from "@cloudscape-design/components";
import Header from "@cloudscape-design/components/header";
import ContentLayout from "@cloudscape-design/components/content-layout";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Button from "@cloudscape-design/components/button";
import React, { useContext } from 'react';

export default function HomePageHeader() {
    return (
        <ContentLayout
            header={
                <CustomHomeHeader />
            }
        >
        </ContentLayout>
    );
}

export function CustomHomeHeader() {
    
    return (
        <Box padding={{ vertical: 'xs', horizontal: 's' }}>
            <Grid
            gridDefinition={[
              { colspan: { l: 10, xxs: 10 } },
              { colspan: { xl: 6, l: 5, s: 6, xxs: 10 } },
              { colspan: { xl: 2, l: 3, s: 3, xxs: 10 }, offset: { s: 3 } },
            ]}
          >
            <Box fontWeight="light" padding={{ top: 'xs' }}>
              <span className="custom-home__category">Internal Tools</span>
            </Box>
            <div className="custom-home__header-title">
              <Box variant="h1" fontWeight="heavy" padding="n" fontSize="display-l" color="inherit">
                Demo Repository
              </Box>
              <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Build, Share and Discover Demos
              </Box>
            </div>
            <div className="custom-home__header-cta">
              <Container>
                <SpaceBetween size="xl">
                  <Box variant="h2" padding="n">
                    Discover Available Demos
                  </Box>
                  <p>Search available demos, built by the community for the community</p>
                  <Button href="/search" variant="primary">
                    Get Started
                  </Button>
                </SpaceBetween>
              </Container>
            </div>
          </Grid>
        </Box>
    )
}