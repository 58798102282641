import { Box, Button, Cards, Header, SpaceBetween } from "@cloudscape-design/components"

export default function FeaturesSection() {
    return (
        <>
            <Box margin={{ top: "xxl" }} fontSize="heading-xl"
                fontWeight="bold"
                variant="h1" tagOverride="h2" padding={{ bottom: 's', top: 'n' }}>
                    Features
            </Box>
            <Cards
                ariaLabels={{
                itemSelectionLabel: (e, t) => `select ${t.name}`,
                selectionGroupLabel: "Item selection"
                }}
                cardDefinition={{
                header: item => (
                    <Header>
                    {item.name}
                    </Header>
                ),
                sections: [
                    {
                    id: "description",
                    content: item => item.description
                    },
                    {
                    id: "link",
                    content: item => <Button href={item.feature_link} >{item.feature_button}</Button>
                    }
                ]
                }}
                cardsPerRow={[
                { cards: 1 },
                { minWidth: 500, cards: 2 }
                ]}        
                items={[
                {
                    name: "Create Demos",
                    description: "Create your own demos which can beshared with others. This provides a central repository for you to store your demo and share with your colleagues.",
                    feature_link: "/demo/create",
                    feature_button: "Create Your First Demo"
                },
                {
                    name: "Search For Demos",
                    description: "Browse the existing catalogue of demos to see if there is already one for your specific usecase. Clone it locally and deploy it into your AWS account.",
                    feature_link: "/search",
                    feature_button: "Search For Existing Demos"
                }
                ]}
                loadingText="Loading resources"
                empty={
                <Box
                    margin={{ vertical: "xs" }}
                    textAlign="center"
                    color="inherit"
                >
                    <SpaceBetween size="m">
                    <b>No resources</b>
                    <Button>Create resource</Button>
                    </SpaceBetween>
                </Box>
                }
            />
        </>
    )
}