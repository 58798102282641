import { Button, Header, Link, Pagination, PropertyFilter, SpaceBetween, Table } from "@cloudscape-design/components";
import { Query } from "@cloudscape-design/components/property-filter/interfaces";
import * as React from "react";
import { Metadata } from "../../classes/metadata";
import { IDTokenContext } from "../../context/idTokenContext";

interface DemoListItem {
    id: string,
    title: string,
    topic: string,
    services: string,
    authors: string,
    rating: string,
    last_published: string,
    build_version?: string
}

interface DemoListTableProps {
    headerTitle: string,
    editable?: boolean,
    hideCreate?: boolean,
    filteringPlaceholderText?: string,
    displayBuildVersion?: boolean,
    setErrorText: CallableFunction
}

interface SearchPageHeaderProps {
    headerTitle: string,
    editable?: boolean,
    hideCreate?: boolean
    demo?: DemoListItem
}

interface SearchPropertyFilterProps {
  filteringPlaceholderText?: string,
  topicsList: string[],
  servicesList: string[]
}

export default function DemoListTable({headerTitle, editable, hideCreate, filteringPlaceholderText, displayBuildVersion, setErrorText}: DemoListTableProps) {

    const [items, setItems] = React.useState<Array<DemoListItem>>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [pagination, setPagination] = React.useState<any>(null);
    const [selectedItems, setSelectedItems] = React.useState<Array<DemoListItem>>([]);
    const [topicsList, setTopicsList] = React.useState<string[]>([]);
    const [servicesList, setServicesList] = React.useState<string[]>([]);

    const idToken = React.useContext(IDTokenContext);

    if(editable === undefined){
      editable = false;
    }

    async function GetItems(setLoading: any, setPagination: any) {
      const newItems = await GetDisplayItems();
      setItems(newItems);
      await Metadata.getTopics(idToken.toString(), setTopicsList, setErrorText)
      await Metadata.getServices(idToken.toString(), setServicesList, setErrorText)
      setLoading(false);
      setPagination(<Pagination currentPageIndex={1} pagesCount={2} />);
    }

    React.useEffect(() => {
      GetItems(setLoading, setPagination);
    }, []);

    return (
        <Table
        onSelectionChange={({ detail }) =>
          setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        columnDefinitions={[
            {
              id: "title",
              header: "Title",
              cell: item => <Link href={"/demo/" + item.id}>{item.title}</Link>,
              sortingField: "title",
              isRowHeader: true
            },
            {
              id: "topic",
              header: "Topic",
              cell: item => item.topic,
              sortingField: "topic"
            },
            {
              id: "services",
              header: "Service(s)",
              cell: item => item.services,
              sortingField: "services"
            },
            {
              id: "authors",
              header: "Author(s)",
              cell: item => item.authors,
              sortingField: "authors"
            },
            {
              id: "rating",
              header: "Rating",
              cell: item => item.rating,
              sortingField: "rating"
            },
            {
              id: "build_version",
              header: "Build Version",
              cell: item => item.build_version,
              sortingField: "build_version"
            },
            {
              id: "last_published",
              header: "Last Published",
              cell: item => item.last_published,
              sortingField: "last_published"
            }
          ]}
          columnDisplay={[
            { id: "title", visible: true },
            { id: "topic", visible: true },
            { id: "services", visible: true },
            { id: "authors", visible: true },
            { id: "rating", visible: (displayBuildVersion === true) ? false : false /* TODO: Implement ratings in backend */ },
            { id: "build_version", visible: (displayBuildVersion === true) ? true : false },
            { id: "last_published", visible: true }
          ]}
          items={items}
          header={<SearchPageHeader headerTitle={headerTitle} editable={editable} hideCreate={hideCreate} demo={(selectedItems.length > 0) ? selectedItems[0] : undefined} />}
          filter={<SearchPropertyFilter filteringPlaceholderText={filteringPlaceholderText} topicsList={topicsList} servicesList={servicesList} />}
          pagination={pagination}
          selectionType={editable === true ? "single" : undefined}
          variant="full-page"
          loadingText="Loading..."
          loading={loading}
        />
    )
}

function SearchPropertyFilter({filteringPlaceholderText, topicsList, servicesList}:SearchPropertyFilterProps) {
  const [query, setQuery] = React.useState<Query>({
    tokens: [],
    operation: 'and'
  });

  let filteringOptions: any[] = [];

    for(let topic in topicsList){
      filteringOptions.push({
            propertyKey: "topics",
            value: topicsList[topic]
        });
    }

    for(let service in servicesList){
      filteringOptions.push({
            propertyKey: "services",
            value: servicesList[service]
        });
    }

  return (
    <PropertyFilter
      onChange={({ detail }) => setQuery(detail)}
      query={query}
      disableFreeTextFiltering
      hideOperations
        filteringPlaceholder={filteringPlaceholderText !== undefined ? filteringPlaceholderText : "Find demo"}
        filteringProperties={[
          {
            key: "topics",
            propertyLabel: "Topic",
            operators: ["=", "!="],
            groupValuesLabel: "Topics"
          },
          {
            key: "services",
            propertyLabel: "Service",
            operators: ["=", "!="],
            groupValuesLabel: "Services"
          }
        ]}
        filteringOptions={filteringOptions}
    />
  );
}

function SearchPageHeader({headerTitle, editable, hideCreate, demo}: SearchPageHeaderProps) {
  
  const disabled = (demo === undefined) ? true : false;
  const editUrl = (disabled === false) ? "/demo/" + demo?.id + "/edit" : undefined;

  return (
    <Header
        variant="h1"
        actions={
            <SpaceBetween direction="horizontal" size="xs">
                {editable && <Button variant="normal" disabled={disabled} href={editUrl}>
                    Edit demo
                </Button>}
                {(hideCreate !== true) && <Button variant="primary" href="/demo/create">
                    Create demo
                </Button>}
            </SpaceBetween>
        }
        counter="(160)"
        info={<Link variant="info">Info</Link>}
        >
        {headerTitle}
    </Header>
  )
}

function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

async function GetDisplayItems() {
  
  //Emulate API call
  await sleep(1000);

  var displayItems = [];
  const today = new Date();
  const date = today.getFullYear() + '-' + 
    ('0' + (today.getMonth()+1)).slice(-2) + '-' +
    ('0' + today.getDate()).slice(-2);

  const topics = ["Generative AI", "Machine Learning", "Blockchain"];
  const services = ["Amazon Bedrock", "Amazon SageMaker, Amazon Comprehend", "Amazon QLDB"];

  for (var i = 0; i < 15; i++) {

    const randomIndex = Math.floor(Math.random() * topics.length);

    displayItems.push(
      {
        id: Math.random().toString(36).substring(2),
        title: "Demo Title (" + (i+1) + ")",
        topic: topics[randomIndex],
        services: services[randomIndex],
        authors: "Joe Bloggs",
        rating: "4.37",
        last_published: date,
        build_version: "v0.1"
      }
    );
  }

  return displayItems;
}