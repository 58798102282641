import { Button, Popover, StatusIndicator } from "@cloudscape-design/components";

interface CopyToClipboardProps {
    displayValue: string;
    copyValue: string;
}

export default function CopyToClipboard({displayValue, copyValue}: CopyToClipboardProps) {
    return (
        <div>
            <Popover
                dismissButton={false}
                position="top"
                size="small"
                triggerType="custom"
                content={
                    <StatusIndicator type="success">
                        Copied to clipboard!
                    </StatusIndicator>
                }
            >
                <Button variant="inline-icon" iconName="copy" onClick={({}) => {
                    navigator.clipboard.writeText(copyValue);
                }} />
            </Popover>
            {displayValue}
        </div>
    )
}