import { Box, Select, Textarea } from "@cloudscape-design/components";
import LabelValueContent from "../../../../components/label_value_content";
import React from "react";
import { OptionDefinition } from "@cloudscape-design/components/internal/components/option/interfaces";

export default function FlagDemoModal(){
    const [reasonForFlagging, setReasonForFlagging] = React.useState<OptionDefinition | null>(null);
    const [additionalDetails, setAdditionalDetails] = React.useState("");

    return(
        <Box>
            <LabelValueContent
                label="Reason for Flagging"
                value={
                    <Select
                        selectedOption={reasonForFlagging}
                        onChange={({ detail }) =>
                            setReasonForFlagging(detail.selectedOption)
                        }
                        placeholder="Choose an option from below"
                        options={[
                            {
                                label: "No clear instructions",
                            },
                            {
                                label: "Includes customer/PII data"
                            },
                            {
                                label: "Does not align to Well-Architect best practices"
                            },
                            {
                                label: "Other"
                            }
                        ]}
                    />
                } 
            />
            <LabelValueContent
                label="Additional Details"
                value={<Textarea value={additionalDetails} onChange={({ detail }) => setAdditionalDetails(detail.value)}></Textarea>} 
            />
        </Box>
    )
}