import { Box, ColumnLayout, Container, Header, Link } from "@cloudscape-design/components"

export default function MoreLinksSection(){
    return (
        <Box margin={{ top: "xxxl" }}>
            <Container
                header={
                <Header
                    variant="h2"
                >
                    More Links
                </Header>
                }
            >
                <ColumnLayout columns={1} borders="horizontal">
                    <Link external href="#">
                        Documentation
                    </Link>
                    <Link external href="https://w.amazon.com/bin/view/UKIRISV/Team-Initiatives/ISV-Demo-Repo/">
                        Wiki
                    </Link>
                    <Link external href="https://w.amazon.com/bin/view/UKIRISV/Team-Initiatives/ISV-Demo-Repo/#HFrequentlyAskedQuestions28FAQs29">
                        FAQs
                    </Link>
                </ColumnLayout>
            </Container>
        </Box>
    )
}