import { useContext } from 'react';
import TopNavigation from '@cloudscape-design/components/top-navigation';
import { IDTokenContext } from '../../context/idTokenContext';

export default function LayoutHeader() {
    const idToken = useContext(IDTokenContext);
    const name = (idToken.payload.given_name) + " " + (idToken.payload.family_name);
    const email = (idToken.payload.email) + "";

    return (
        <div id="top-nav">
            <TopNavigation
            identity={{
                logo: { src: '/logo.png', alt: 'Demo Repository' },
                title: 'Demo Repository',
                href: '/',
            }}
            utilities={[/*{
                type: "button",
                href: "#Settings",
                iconName: "settings",
                ariaLabel: "Settings",
                title: "Settings"
            },*/
                {
                type: "menu-dropdown",
                text: name,
                description: email,
                iconName: "user-profile",
                items: [
                    { iconName: "suggestions", id: "feature_ticket", text: "Feature Request", href: "https://t.corp.amazon.com/create/templates/a3df2dd4-cac5-45fa-8c54-47dba9635cc3" },
                    { iconName: "bug", id: "issue_ticket", text: "Report A Bug", href: "https://t.corp.amazon.com/create/templates/0ceee7d1-a345-4afd-b7ba-a650b1421f92" },
                ]
                }
            ]}
            />
      </div>
    );
}